import React, { useState } from 'react';
import axios from 'axios';

const Upload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState('');
    const [fileUrl, setFileUrl] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setProgress(0);
        setMessage('');
        setFileUrl('');
    };

    const handleFileUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post('/files/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (event) => {
                    const percentCompleted = Math.round((event.loaded * 100) / event.total);
                    setProgress(percentCompleted);
                }
            });

            setMessage(`File "${selectedFile.name}" uploaded successfully! 🎉`);
            setFileUrl(response.data.url);
        } catch (error) {
            console.error('Upload failed:', error);
            setMessage('Upload failed. Please try again.😪');
        }
    };

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(fileUrl).then(() => {
            setMessage('URL copied to clipboard!');
        }, (err) => {
            console.error('Could not copy text: ', err);
            setMessage('Failed to copy URL.');
        });
    };

    return (
        <div>
            <h2>File Upload</h2>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload</button>
            <div>
                <progress value={progress} max="100"></progress>
                <span>{progress}%</span>
            </div>
            {message && <p>{message}</p>}
            {fileUrl && (
                <div>
                    <p>File URL:</p>
                    <a href={fileUrl} target="_blank" rel="noopener noreferrer">{fileUrl}</a>
                    <button onClick={handleCopyUrl}>Copy URL</button>
                </div>
            )}
        </div>
    );
};

export default Upload;