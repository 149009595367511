import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AccessControl = ({ setAuthenticated }) => {
    const navigate = useNavigate();
    const [inputPassword, setInputPassword] = useState('');
    const [error, setError] = useState('');

    const correctPassword = 'Goshare7788@'; // Set your desired password here

    const handlePasswordCheck = (event) => {
        event.preventDefault();
        if (inputPassword === correctPassword) {
            setAuthenticated(true);
            navigate('/upload'); // Navigate to the upload page if the password is correct
        } else {
            setError('Incorrect password. Please try again.');
        }
    };

    return (
        <div>
            <h2>Enter Password to Access Upload</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handlePasswordCheck}>
                <input
                    type="password"
                    value={inputPassword}
                    onChange={(e) => setInputPassword(e.target.value)}
                    placeholder="Enter password"
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default AccessControl;