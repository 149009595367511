import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Upload from './components/Upload';
import ViewUploads from './components/ViewUploads';
import AccessControl from './components/AccessControl';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            authenticated ? (
              <Navigate to="/upload" />
            ) : (
              <AccessControl setAuthenticated={setAuthenticated} />
            )
          }
        />
        <Route
          path="/upload"
          element={
            authenticated ? (
              <Layout>
                <Upload />
              </Layout>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/view-uploads"
          element={
            authenticated ? (
              <Layout>
                <ViewUploads />
              </Layout>
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;