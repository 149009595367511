import React, { useState, useEffect } from 'react';

const ViewUploads = () => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await fetch('/files/list');  // Adjust the URL and port if necessary
                const data = await response.json();
                setFiles(data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchFiles();
    }, []);

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            alert('URL copied to clipboard!');
        }, (err) => {
            alert('Failed to copy URL: ', err);
        });
    };

    // Function to construct S3 bucket URL
    const getS3Url = (key) => {
        return `https://s3.ap-southeast-3.amazonaws.com/docs.goshare.asia/${key}`;
    };

    if (loading) {
        return <p>Loading files...</p>;
    }

    if (error) {
        return <p>Error fetching files: {error.message}</p>;
    }

    return (
        <div>
            <h2>Uploaded Files</h2>
            <table>
                <thead>
                    <tr>
                        <th>File Name</th>
                        <th>Uploaded Date</th>
                        <th>File URL</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {files.length > 0 ? files.map((file) => (
                        <tr key={file.Key}>
                            <td>{file.Key.replace('uploads/', '')}</td>
                            <td>{new Date(file.LastModified).toLocaleString()}</td>
                            <td>
                                <a href={getS3Url(file.Key)}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    View File
                                </a>
                            </td>
                            <td>
                                <button onClick={() => copyToClipboard(getS3Url(file.Key))}>
                                    Copy URL
                                </button>
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan="4">No files found in the uploads directory. 😪</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ViewUploads;